import PropTypes from 'prop-types';
import {createElement} from "react";

function CircleButton({
  color, size, isOpen, tag = 'button',
}) {
  const SIZES = {
    wrapper: {
      default: 'w-12 h-12',
      small: 'w-[30px] h-[30px]',
    },
    inside: {
      default: 'w-6 h-6 p-1',
      small: 'w-[15px] h-[15px] p-1',
    },
  };

  return (
    createElement(tag, {type: "button", className: `${SIZES.wrapper[size]} border border-white border-solid bg-white bg-opacity-50 rounded-full flex items-center justify-center`}, (
      <div className={`${SIZES.inside[size]} rounded-full bg-white flex items-center justify-center relative`}>
        <span className={color}>
          {isOpen ? '-' : '+'}
        </span>
      </div>
    ))
  );
}

export default CircleButton;

CircleButton.propTypes = {
  color: PropTypes.oneOf([
    'text-action-yellow',
    'text-action-green',
    'text-action-blue',
    'text-action-red',
    'text-action-orange',
    'text-action-platinum',
    'text-action-pink',
  ]),
  size: PropTypes.oneOf(['default', 'small']),
  isOpen: PropTypes.bool,
};

CircleButton.defaultProps = {
  color: 'platinum',
  size: 'default',
  isOpen: false,
};
