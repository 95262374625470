import { ReactComponent as GraphicImage } from 'assets/images/graphic.svg';
import BasketballElement from 'components/_domains/ActionPage/Graphic/Basketball.element';
import PropTypes from 'prop-types';
import VolleyballElement from 'components/_domains/ActionPage/Graphic/Volleyball.element';
import OtherElement from 'components/_domains/ActionPage/Graphic/Other.element';
import FootballElement from 'components/_domains/ActionPage/Graphic/Football.element';
import TennisElement from 'components/_domains/ActionPage/Graphic/Tennis.element';
import { useState } from 'react';
import DocumentsElement from 'components/_domains/ActionPage/Graphic/Documents.element';
import { useScreenSize } from 'hooks/useScreenSize';

function Graphic({
  handleCurrentElement, currentElement,
}) {
  const [currentHover, setCurrentHover] = useState('');
  const { screenSize } = useScreenSize();

  const handleElement = (element) => {
    handleCurrentElement(element);

    if (screenSize < 1023) {
      document.getElementById('action-page-hero').scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="relative overflow-hidden md:overflow-visible">
      <GraphicImage className="w-[270px] h-[270px] sm:w-[440px] sm:h-[440px] lg:w-[320px] lg:h-[320px] xl:w-[408px] xl:h-[408px]" />
      <BasketballElement handleHover={setCurrentHover} currentHover={currentHover} handleClick={handleElement} currentElement={currentElement} />
      <VolleyballElement handleHover={setCurrentHover} currentHover={currentHover} handleClick={handleElement} currentElement={currentElement} />
      <OtherElement handleHover={setCurrentHover} currentHover={currentHover} handleClick={handleElement} currentElement={currentElement} />
      <DocumentsElement handleHover={setCurrentHover} currentHover={currentHover} handleClick={handleElement} currentElement={currentElement} />
      <FootballElement handleHover={setCurrentHover} currentHover={currentHover} handleClick={handleElement} currentElement={currentElement} />
      <TennisElement handleHover={setCurrentHover} currentHover={currentHover} handleClick={handleElement} currentElement={currentElement} />
    </div>
  );
}

export default Graphic;

Graphic.propTypes = {
  handleCurrentElement: PropTypes.func.isRequired,
  currentElement: PropTypes.string,
};

Graphic.defaultProps = {
  currentElement: null,
};
