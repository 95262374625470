import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  useEffect,
  useState,
} from 'react';

function VideoItem({
  title, videoSrc,
}) {
  const [animation, setAnimation] = useState(false);
  const { t } = useTranslation();

  useEffect(() => () => {
    setAnimation(false);
  }, [videoSrc]);

  if (!animation) {
    setTimeout(() => {
      setAnimation(true);
    }, 1);

    return '';
  }

  return (
    <div className="flex items-center w-full animation-zoom animation-fade">
      <div className="block w-full">
        {title && (
          <span className="text-white lg:text-action-onyx text-sm font-medium block mb-4 text-left">
            {t(title)}
          </span>
        )}
        <div className="iframe-container">
          <iframe
            width="800"
            height="315"
            src={videoSrc}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className="w-full h-auto responsive-iframe"
          />
        </div>
      </div>
    </div>
  );
}

export default VideoItem;

VideoItem.propTypes = {
  title: PropTypes.string,
  videoSrc: PropTypes.string.isRequired,
};

VideoItem.defaultProps = {
  title: null,
};
