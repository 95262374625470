import { COLOR_VARIABLES } from 'constants/index';

export const CIRCLE_ELEMENTS_NAME = {
  basketball: 'basketball',
  football: 'football',
  tennis: 'tennis',
  volleyball: 'volleyball',
  other: 'other',
  documents: 'documents',
};

export const CIRCLE_ELEMENTS_OPTION = {
  [CIRCLE_ELEMENTS_NAME.basketball]: {
    accentColor: COLOR_VARIABLES.green,
    tooltipText: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.basketball}.tooltipText`,
    tooltipHelper: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.basketball}.tooltipHelper`,
    dropdown: {
      title: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.basketball}.title`,
      items: [
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.basketball}.items.0`,
          videoSrc: 'https://www.youtube.com/embed/Jj9uL_722ow',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.basketball}.items.1`,
          videoSrc: 'https://www.youtube.com/embed/S8wec9cUuyw',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.basketball}.items.2`,
          videoSrc: 'https://www.youtube.com/embed/gsV-Ac5yTVc',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.basketball}.items.3`,
          videoSrc: 'https://www.youtube.com/embed/aPGrPkvFlDs',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.basketball}.items.4`,
          videoSrc: 'https://www.youtube.com/embed/Tmp3sXMuqOU',
        },
      ],
      videoSrc: 'https://www.youtube.com/embed/SNSuEZ88JXg',
      videoTitle: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.basketball}.videoTitle`,
    },
  },
  [CIRCLE_ELEMENTS_NAME.volleyball]: {
    accentColor: COLOR_VARIABLES.yellow,
    tooltipText: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.volleyball}.tooltipText`,
    tooltipHelper: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.volleyball}.tooltipHelper`,
    dropdown: {
      title: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.volleyball}.title`,
      items: [
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.volleyball}.items.0`,
          videoSrc: 'https://www.youtube.com/embed/zRhpCflVXOY',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.volleyball}.items.1`,
          videoSrc: 'https://www.youtube.com/embed/TUv9bnXNS7A',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.volleyball}.items.2`,
          videoSrc: 'https://www.youtube.com/embed/X4TEqmrGdsg',
        },
      ],
      videoSrc: 'https://www.youtube.com/embed/beaM_8jinY0',
      videoTitle: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.volleyball}.videoTitle`,
    },
  },
  [CIRCLE_ELEMENTS_NAME.other]: {
    accentColor: COLOR_VARIABLES.blue,
    tooltipText: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.other}.tooltipText`,
    tooltipHelper: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.other}.tooltipHelper`,
    dropdown: {
      title: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.other}.title`,
      items: [
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.other}.items.0`,
          videoSrc: 'https://www.youtube.com/embed/BKccBA3RNOU',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.other}.items.1`,
          videoSrc: 'https://www.youtube.com/embed/w88opOKHnm4',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.other}.items.2`,
          videoSrc: 'https://www.youtube.com/embed/W60uEznQA1A',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.other}.items.3`,
          videoSrc: 'https://www.youtube.com/embed/jD0JSMT92_c',
        },
      ],
      videoSrc: 'https://www.youtube.com/embed/0qNV_M8ITEE',
      videoTitle: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.other}.videoTitle`,
    },
  },
  [CIRCLE_ELEMENTS_NAME.football]: {
    accentColor: COLOR_VARIABLES.orange,
    tooltipText: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.football}.tooltipText`,
    tooltipHelper: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.football}.tooltipHelper`,
    dropdown: {
      title: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.football}.title`,
      items: [
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.football}.items.0`,
          videoSrc: 'https://www.youtube.com/embed/CmI8Z5Boiug',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.football}.items.1`,
          videoSrc: 'https://www.youtube.com/embed/VxCTs-OMOPQ',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.football}.items.2`,
          videoSrc: 'https://www.youtube.com/embed/-7C_7QH7GtQ',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.football}.items.3`,
          videoSrc: 'https://www.youtube.com/embed/UXbvuF5W1KY',
        },
      ],
      videoSrc: 'https://www.youtube.com/embed/E6294YA0UD0',
      videoTitle: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.football}.videoTitle`,
    },
  },
  [CIRCLE_ELEMENTS_NAME.tennis]: {
    accentColor: COLOR_VARIABLES.red,
    tooltipText: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.tennis}.tooltipText`,
    tooltipHelper: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.tennis}.tooltipHelper`,
    dropdown: {
      title: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.tennis}.title`,
      items: [
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.tennis}.items.0`,
          videoSrc: 'https://www.youtube.com/embed/pbzVwjbdpcA',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.tennis}.items.1`,
          videoSrc: 'https://www.youtube.com/embed/61EduFYiHas',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.tennis}.items.2`,
          videoSrc: 'https://www.youtube.com/embed/lJWAt8yq3Fs',
        },
        {
          label: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.tennis}.items.3`,
          videoSrc: 'https://www.youtube.com/embed/jWF3qDjJYGI',
        },
      ],
      videoSrc: 'https://www.youtube.com/embed/WF212MeOsC4',
      videoTitle: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.tennis}.videoTitle`,
    },
  },
  [CIRCLE_ELEMENTS_NAME.documents]: {
    accentColor: COLOR_VARIABLES.pink,
    tooltipText: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.documents}.tooltipText`,
    tooltipHelper: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.documents}.tooltipHelper`,
    dropdown: {
      title: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.documents}.title`,
      items: [],
      videoSrc: 'https://www.youtube.com/embed/NVuVR7FTPPY',
      videoTitle: `actionPage.circle.${CIRCLE_ELEMENTS_NAME.documents}.videoTitle`,
    },
  },
};
