import Cookie from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import {
  useEffect,
  useState,
} from 'react';

function Cookies() {
  const cookie = new Cookie(null);
  const { t } = useTranslation();
  const currentDate = new Date();
  const cookieTime = currentDate.setDate(currentDate.getDate() + 30);
  const currentCookie = cookie.get('action-page-cookies');
  const [state, setState] = useState(currentCookie);

  const handleCookie = () => {
    cookie.set('action-page-cookies', true, { expires: new Date(cookieTime) });
    setState(true);
  };

  // cookie.remove('action-page-cookies');

  useEffect(() => {
    if (currentCookie) {
      setState(true);
    }
  }, [currentCookie]);

  if (state) {
    return '';
  }

  return (
    <div className="bg-action-onyx fixed bottom-0 left-0 right-0 py-5 z-[9999]">
      <div className="container px-3.5 gap-4 flex flex-wrap w-full">
        <span className="text-white">
          {t('cookies.content')}
        </span>
        <button
          type="button"
          onClick={handleCookie}
          className="px-3 py-1 bg-action-pink rounded-sm text-white text-sm inline-flex items-center font-medium"
        >
          {t('cookies.agreeButton')}
        </button>
        <a
          href="https://govsport.eu/__trashed-2__trashed/"
          target="_blank"
          rel="noreferrer"
          className="px-3 py-1 bg-action-pink rounded-sm text-white text-sm inline-flex items-center font-medium"
        >
          {t('cookies.privacyPolicyButton')}
        </a>
      </div>
    </div>
  );
}

export default Cookies;
