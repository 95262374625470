import { CIRCLE_ELEMENTS_OPTION } from 'constants/index';
import HeadingWithDecor from 'components/HeadingWithDecor/HeadingWithDecor';
import { Graphic } from 'components/_domains/ActionPage/index';
import { useState } from 'react';
import DropdownAccordion from 'components/_domains/ActionPage/DropdownAccordion/DropdownAccordion';
import VideoItem from 'components/_domains/ActionPage/VideoItem/VideoItem';
import CheckmarkListElem from "components/CheckmarkListElem/CheckmarkListElem";
import Container from "components/Grid/Container";
import TooltipNonAbsolute from "components/Tooltip/TooltipNonAbsolute";
import { ReactComponent as CursorIcon } from 'assets/images/cursor.svg';
import {useContentContext} from "context/ContentContext";

function Hero() {
  const [currentElement, setCurrentElement] = useState('');
  const [currentVideoSrc, setCurrentVideoSrc] = useState(null);

  const OPTION = currentElement in CIRCLE_ELEMENTS_OPTION ? CIRCLE_ELEMENTS_OPTION[currentElement] : {};
  const isElementSelected = Object.values(OPTION).length !== 0;

  const {data} = useContentContext();

  return (
    <section id="action-page-hero" className="relative overflow-hidden">
      <Container className={`${isElementSelected ? "py-0" : "py-14"} lg:py-14 lg:pb-0 z-10`}>
        <div className={`${isElementSelected ? 'hidden lg:grid' : ''} grid grid-cols-2 gap-5`}>
          <div className="col-span-2 lg:col-span-1">
            <HeadingWithDecor title={data?.action_plus__header_text ?? ""} className="text-4.5xl uppercase"/>
          </div>
          <div className="col-span-2 lg:col-span-1 flex justify-end">
            <div className="w-full lg:w-[505px] max-w-full">
              {data?.action_plus__header_description ?? ""}
            </div>
          </div>
          <div className="col-span-1 hidden lg:block"/>
          <div className="col-span-2 lg:col-span-1 flex justify-end lg:pb-[100px] bg-white relative">
            <div className="hidden lg:block triangle-shape absolute top-0 right-full h-full bg-white w-[180px]" />
            <div className="hidden lg:block absolute top-0 left-full h-full bg-white w-[10000px]" />
            <div className="w-full lg:w-[505px] max-w-full">
              <h4 className="font-dm mb-5 text-xl font-medium">{data?.action_plus__header_list_title ?? ""}</h4>
              <ul className="space-y-5">
                {data?.action_plus__header_list?.map(element => (
                    <CheckmarkListElem key={element.element}>
                      {element.element}
                    </CheckmarkListElem>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
      <div id="action-page-learning" className="bg-action-light-gray lg:-mt-[200px]">
        <Container className="pt-14 pb-28 lg:pb-40 lg:pt-[100px]">
          <div className={`${isElementSelected ? "hidden lg:flex" : ""} flex flex-col gap-5 w-full lg:w-[505px] max-w-full`}>
            <HeadingWithDecor title={`${data?.action_plus__learning_title ?? 'Interactive Learning Hub'}`} />
            <p className="text-sm">
              {data?.action_plus__learning_description ?? ""}
            </p>
          </div>
          <div className={`grid grid-cols-1 lg:grid-cols-3 gap-3.5 lg:mt-0 ${isElementSelected && "-mt-14"}`}>
            <div className="flex flex-wrap items-center">
              {isElementSelected && (
                  <DropdownAccordion
                      dropdownData={OPTION}
                      handleCloseElement={() => setCurrentElement(null)}
                      setCurrentVideoSrc={setCurrentVideoSrc}
                  />
              )}
            </div>
            <div className={`${isElementSelected ? 'hidden lg:flex' : ''} flex flex-wrap justify-center py-14`}>
              <Graphic handleCurrentElement={setCurrentElement} currentElement={currentElement}/>
            </div>
            <div className="hidden lg:flex items-center w-full">
              {isElementSelected && (
                  <VideoItem videoSrc={currentVideoSrc || OPTION.dropdown.videoSrc} title={OPTION.dropdown.videoTitle}/>
              )}
            </div>
          </div>
          <div className={`${isElementSelected ? "hidden" : "lg:flex"} flex justify-center`}>
            <TooltipNonAbsolute className="w-[300px] max-w-full text-sm">
              <div className="flex items-center gap-2">
                <div>
                  <CursorIcon/>
                </div>
                Click on the area you're interested in
              </div>
            </TooltipNonAbsolute>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Hero;
