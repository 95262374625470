import { useTranslation } from 'react-i18next';
import ActionLogo from 'assets/images/action-logo.png';
import Container from "components/Grid/Container";
import { ReactComponent as ArrowUp } from 'assets/images/arrow-up-white.svg';
import { ReactComponent as ArrowDown } from 'assets/images/arrow-down-white.svg';
import {useState} from "react";
import { ReactComponent as CheckmarkIcon } from 'assets/images/check-icon.svg';
import {twMerge} from "tailwind-merge";
import {useContentContext} from "context/ContentContext";
import {NavLink} from "react-router-dom";

function Action() {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const {data} = useContentContext();

  const getItem = (partner, index) => {
    if (partner.link) {
      return (
          <NavLink to={partner.link} key={partner.logo.ID} rel="nofollow" className={`col-span-1 flex items-center justify-start transition-all duration-200`}>
            <img src={partner.logo.url} alt={partner.logo.alt}/>
          </NavLink>
      );
    }

    return (
        <div key={partner.logo.ID} className={`col-span-1 flex items-center justify-start transition-all duration-200`}>
        <img src={partner.logo.url} alt={partner.logo.alt}/>
      </div>
    );
  };

  return (
      <section id="action-page-action" className="-mt-[170px]">
      <Container>
        <div className="bg-white p-14 shadow-lg">
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 lg:col-span-5 flex justify-center items-center">
              <img src={data?.action_plus__about_logo?.url ?? ActionLogo} alt={data?.action_plus__about_logo.alt ?? "action plus"} />
            </div>
            <div className="col-span-12 lg:col-span-7">
              <div className="flex flex-col gap-5">
                <h4 className="font-dm font-bold text-2xl uppercase">{data?.action_plus__about_title ?? 'About ACTION'}</h4>
                <p>
                  {data?.action_plus__about_description ?? t('actionPage.founded.description')}
                </p>
                <button
                    type="button"
                    id="ohCQpJsH8dA3B4L6"
                    onClick={() => setOpen((prevState) => !prevState)}
                    className="flex gap-2 items-center leading-none transition-colors relative text-action-pink"
                >
                  {isOpen ? t('globalLabels.collapse') : t('globalLabels.expand')}
                  {isOpen ? (
                      <ArrowUp
                          className="[&_path]:fill-action-pink"/>
                  ) : (
                      <ArrowDown
                          className="[&_path]:fill-action-pink"/>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className={`${isOpen ? "flex" : "hidden"} flex-col pt-14`}>
            <div className="flex flex-col gap-5 py-10 border-t border-action-onyx border-solid">
              <h4 className="text-lg font-medium font-dm text-center">{data?.action_plus__about_details_title ?? ''}</h4>
              <div className="grid grid-cols-3 gap-5 ">
                {data?.action_plus__about_details?.map(details => (
                  <CheckmarkElem key={details.text} className="col-span-3 lg:col-span-1 ">
                    {details.text}
                  </CheckmarkElem>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-5 py-10 border-t border-action-onyx border-solid">
              <h4 className="text-lg font-medium font-dm text-center">Partners:</h4>
              <div className="flex flex-wrap justify-center gap-10">
                {data?.action_plus__about_details_partners?.map(getItem)}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Action;


function CheckmarkElem({children, className = ""}) {
    return (
        <div className={twMerge("flex flex-col gap-2", className)}>
          <CheckmarkIcon/>
          <p className="text-sm">
            {children}
          </p>
        </div>
    );
}
