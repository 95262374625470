import React from 'react';
import {twMerge} from "tailwind-merge";

function Container({children, className = ''}) {
    return (
        <div className={twMerge("container px-4 xl:px-0", className)}>{children}</div>
    );
}

export default Container;
