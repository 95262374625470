import { useTranslation } from 'react-i18next';
import FooterBg from 'assets/images/footer-bg.png';
import HeadingWithDecor from 'components/HeadingWithDecor/HeadingWithDecor';
import Cookies from 'components/Cookies/Cookies';
import Container from "components/Grid/Container";
import {useContentContext} from "context/ContentContext";
import {NavLink} from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  const {data} = useContentContext();

  const getItem = (partner, index) => {
    if (partner.link) {
      return (
          <NavLink to={partner.link} key={partner.logo.ID} rel="nofollow">
            <img src={partner.logo.url} alt={partner.logo.alt}/>
          </NavLink>
      );
    }

    return (
      <div key={partner.logo.ID}>
        <img src={partner.logo.url} alt={partner.logo.alt}/>
      </div>
    );
  };

  return (
      <footer
          id="action-footer"
      className="py-14 bg-no-repeat bg-center bg-cover"
      style={{
        backgroundImage: `url("${FooterBg}")`,
      }}
    >
      <Container className="flex flex-col">
        <div className="flex justify-center">
          <div className="flex flex-col items-center text-center gap-4 w-[610px] max-w-full">
            <HeadingWithDecor title={data?.action_plus__footer_title ?? t('footer.title')} decorColor="bg-white" headingColor="text-white"/>
            <p className="text-white">
              {data?.action_plus__footer_description ?? ""}
            </p>
            <a href={`mailto:${data?.action_plus__footer_email ?? t('footer.email')}`} className="text-white font-bold font-dm text-2xl">
              {data?.action_plus__footer_email ?? t('footer.email')}
            </a>
          </div>
        </div>
        <div className="w-full flex flex-wrap gap-5 justify-between mt-10 pt-10 border-t-[.5px] border-white border-solid">
          <div>
            <div className="flex flex-wrap items-center gap-5">
              {data?.action_plus__footer_logos?.map(getItem)}
            </div>
          </div>
          <div className="flex items-center text-white text-sm">
            <p>
              {t('footer.copyright')}
            </p>
          </div>
        </div>
      </Container>
      <Cookies/>
    </footer>
  );
}

export default Footer;
