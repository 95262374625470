import {
  useCallback,
  useEffect,
  useState,
} from 'react';

export function useScreenSize() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const resizeListener = useCallback(() => {
    setScreenSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return {
    screenSize,
  };
}
