import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SubMenu({ items }) {
  const { t } = useTranslation();

  return (
    <ul className="hidden group-hover:block group-focus:block w-full mt-2.5 md:mt-0 md:absolute left-0 top-full min-w-[200px] bg-action-light-gray shadow py-2.5 z-50">
      {items.map((subItem) => (
        <li key={subItem.id} className="hover:bg-white py-2.5 px-3.5">
          <NavLink to={subItem.path} className="w-full inline-block">
            {t(subItem.label)}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

export default SubMenu;

SubMenu.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
};
