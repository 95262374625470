import Logo from 'components/Logo/Logo';
import Navigation from 'components/Navigation/Navigation';
import Container from "components/Grid/Container";

function Header() {
  return (
    <header className="bg-white z-30 relative">
      <Container className="py-7 flex flex-wrap justify-between items-center">
        <Logo />
        <Navigation />
      </Container>
    </header>
  );
}

export default Header;
