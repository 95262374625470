import HeadingWithDecor from 'components/HeadingWithDecor/HeadingWithDecor';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Container from "components/Grid/Container";
import {useContentContext} from "context/ContentContext";

function Partners() {
  const { t } = useTranslation();
  const {data} = useContentContext();

  const getItem = (partner, index) => {
    if (partner.link) {
      return (
        <NavLink to={partner.link} key={partner.logo.ID} rel="nofollow" className={`col-span-1 flex items-center justify-start transition-all duration-200`}>
          <img src={partner.logo.url} alt={`partner ${index}`} />
        </NavLink>
      );
    }

    return (
      <span key={partner.logo.ID} className={`col-span-1 flex items-center justify-start transition-all duration-200`}>
        <img src={partner.logo.url} alt={`partner ${index}`} />
      </span>
    );
  };

  return (
    <section id="action-page-partners" className="bg-action-light-gray pt-24 pb-[220px] lg:pb-[270px]">
      <Container>
        <HeadingWithDecor title={data?.action_plus__partners_title ?? t('actionPage.partners.title')} className="mb-14" />
        <div className="col-span-9">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10">
            {data?.action_plus__partners?.map(getItem)}
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Partners;
