import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import 'styles/index.css';
import { BrowserRouter } from 'react-router-dom';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ContentProvider} from "context/ContentContext";

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <ContentProvider>
                <App />
            </ContentProvider>
        </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
