import { useTranslation } from 'react-i18next';
import { ReactComponent as EULogo } from 'assets/images/eu-logo.svg';
import Badge from 'components/Badge/Badge';
import Container from "components/Grid/Container";
import {useContentContext} from "context/ContentContext";

function Founded() {
  const { t } = useTranslation();
  const {data} = useContentContext();

  return (
    <section id="action-page-founded">
      <Container>
        <div className="p-14 grid grid-cols-12 gap-5">
          <div className="col-span-12 lg:col-span-5 flex justify-center items-center">
            <EULogo className="w-[250px] h-[53px] md:w-[349px] md:h-[74px]" />
          </div>
          <div className="col-span-12 lg:col-span-7">
            <Badge>
              {t('actionPage.founded.disclaimer')}
            </Badge>
            <p className="mt-5">
              {data?.action_plus__about_disclaimer ?? ""}
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Founded;
