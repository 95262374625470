import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDownBlack } from 'assets/images/arrow-down-black.svg';
import SubMenu from 'components/Navigation/SubMenu';
import {scrollTo} from "utils/functions";

function NavigationItem({
  label, path, items,
}) {
  const hasItems = items.length !== 0;
  const { t } = useTranslation();

  const scrollToElement = () => {
    scrollTo(path);
  }

  return (
    <li className="w-full md:w-auto group relative py-2.5 flex flex-wrap gap-2 items-center justify-between">
      <button
        onClick={scrollToElement}
        className={'text-action-onyx relative leading-none after:content-[\'\'] after:absolute after:-bottom-0.5 after:left-0 after:h-0.5 after:bg-action-pink hover:after:w-full after:transition-width after:duration-200 text-lg lg:text-base after:w-0'}
      >
        {t(label)}
      </button>
      {hasItems && (<ArrowDownBlack />)}
      {hasItems && (
        <SubMenu items={items} />
      )}
    </li>
  );
}

export default NavigationItem;

NavigationItem.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object),
};

NavigationItem.defaultProps = {
  items: [],
};
