import PropTypes from 'prop-types';
import {twMerge} from "tailwind-merge";

function HeadingWithDecor({
  title, decorColor, headingColor, className
}) {
  return (
    <div className="relative">
      <div className={`${decorColor} h-full w-1 absolute left-0 top-0`} />
      <h2 className={twMerge(`font-bold w-full font-dm pl-3.5 text-3.5xl !leading-[1.2em]`, headingColor, className)}>
        {title}
      </h2>
    </div>
  );
}

export default HeadingWithDecor;

HeadingWithDecor.propTypes = {
  title: PropTypes.string.isRequired,
  decorColor: PropTypes.oneOf(['bg-action-pink', 'bg-white']),
  headingColor: PropTypes.oneOf(['text-action-onyx', 'text-white']),
  className: PropTypes.string
};

HeadingWithDecor.defaultProps = {
  decorColor: 'bg-action-pink',
  headingColor: 'text-action-onyx',
  className: ''
};
