import PropTypes from 'prop-types';
import { ReactComponent as DangerIconWhite } from 'assets/images/danger-icon-white.svg';

function Badge({
  children,
}) {
  return (
    <div className="px-3 py-2.5 bg-action-blue rounded-sm text-white text-sm inline-flex items-center font-medium">
      <DangerIconWhite className="mr-1.5" />
      {children}
    </div>
  );
}

export default Badge;

Badge.propTypes = {
  children: PropTypes.node.isRequired,
};
