export const navigationConstant = [
  {
    label: 'navigation.aboutUs',
    path: '#action-page-hero',
    items: [],
    id: 'mNqRJEapW19Dgclp',
  },
  {
    label: 'navigation.learningHub',
    path: '#action-page-learning',
    items: [],
    id: 'emKo3Pn64sbMv9ve',
  },
  {
    label: 'navigation.resources',
    path: '#action-page-download-resources',
    items: [],
    id: 'Oy7ADMl8n690l41r',
  },
  {
    label: 'navigation.partners',
    path: '#action-page-partners',
    items: [],
    id: 'opaN4efg4jt1e29d',
  },
  {
    label: 'navigation.contact',
    path: '#action-footer',
    items: [],
    id: 'YdkyJyGNpRSy59Bn',
  },
];
