import PropTypes from 'prop-types';

function Tooltip({ children }) {
  return (
    <div className="pointer-events-none absolute bottom-[105%] left-1/2 transform -translate-x-1/2 w-max opacity-0 transition-opacity group-hover:opacity-100 bg-white px-5 py-3.5 border border-solid border-action-platinum hidden md:block z-[999]">

      {children}
      <div className="h-0 w-0 border-x-8 border-x-transparent border-b-[10px] border-b-action-platinum absolute top-[101%] left-1/2 transform -translate-x-1/2 rotate-180" />
      <div className="h-0 w-0 border-x-8 border-x-transparent border-b-[10px] border-b-white absolute top-full left-1/2 transform -translate-x-1/2 rotate-180" />
    </div>
  );
}

export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};
