export const enLocale = {
  navigation: {
    aboutUs: 'About Us',
    learningHub: 'Learning Hub',
    resources: 'Resources',
    partners: 'Partners',
    contact: 'Contact',
  },
  footer: {
    title: 'Get in Touch',
    description: 'If you want to cooperate, learn more about us or simply share your activities in the field of sport governance – contact us:',
    email: 'info@govsport.eu',
    copyright: '© 2024. All rights reserved.',
  },
  forms: {
    email: 'e-mail address',
    subscribe: 'subscribe',
  },
  actionPage: {
    hero: {
      title: 'Integrating National Entities for Governance Reform',
      description: 'The project is a direct continuation of ACTION, completed in 2023 with the creation of the Converged Code of Good Governance by Institute for Sport Governance and partners. ACTION+ aims to integrate national agencies and ministries in efforts to implement reforms and disseminate good governance principles.',
    },
    circle: {
      basketball: {
        title: 'responsible federation',
        tooltipText: 'responsible federation',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'The federation educates on anti‐doping and anti-match‐fixing.',
          'The federation promotes fair treatment and inclusion for all its stakeholders.',
          'The federation implements a policy to avoid any form of harassment and abuse.',
          'The federation implements a policy on the development and well-being of its athletes.',
          'The federation ensures its environmental sustainability.',
        ],
      },
      volleyball: {
        title: 'effective general assembly',
        tooltipText: 'effective general assembly',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'Members of the Federation meet at least once a year at the General Assembly.',
          'Federation’s General Assembly supervises the Board’s performance.',
          'Federation publishes the agenda and minutes of the General Assembly on its website.',
        ],
      },
      other: {
        title: 'accountable federation',
        tooltipText: 'accountable federation',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'Federation has a clear structure that defines the role of its Board members in contrast to the role of its paid staff.',
          'Federation publishes its updated statutes, policies, strategies, and internal regulations on its website.',
          'Federation implements an external financial control system.',
          'Federation publishes annual reports, including financial statements, on its website ',
        ],
      },
      football: {
        title: 'Active Board',
        tooltipText: 'Active Board',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'Board members of the Federation hold regular meetings.',
          'Federation’s Board supervises paid staff.',
          'Board members regularly consult committees consisting of representatives of the key stakeholders.',
          'Federation publishes Board decisions on its website.',
        ],
      },
      tennis: {
        title: 'Legitimate board',
        tooltipText: 'Legitimate board',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about this practice:',
        items: [
          'Federation establishes clear conflict of interest procedures for the Board members. (Eligibility for board election)',
          'Federation ensures a differentiated and balanced composition of its Board.',
          'Board members of Federation are democratically (re‐) appointed, according to clear and transparent procedures, including term limit.',
          'Federation publishes Board members’ background information on its website.',
        ],
      },
      documents: {
        title: 'European Convergent Code of Good Governance',
        tooltipText: 'European Convergent Code of Good Governance',
        tooltipHelper: 'Click the "+" to reveal more details',
        videoTitle: 'Watch video to learn more about the Code:',
        items: [],
      },
    },
    downloadResources: {
      title: 'Download Resources',
      items: [
        'Baseline report',
        'Best Practices Report',
        'Converged Code - English',
        'Converged Code - Bulgarian',
        'Converged Code - French',
        'Converged Code - German',
        'Converged Code - Greek',
        'Converged Code - Lithuanian',
        'Converged Code - Maltese',
        'Converged Code - Polish',
        'Action Converged Code - English',
        'Action Converged Code - Bulgarian',
        'Action Converged Code - French',
        'Action Converged Code - German',
        'Action Converged Code - Greek',
        'Action Converged Code - Lithuanian',
        'Action Converged Code - Maltese',
        'Action Converged Code - Polish',
      ],
    },
    partners: {
      title: 'Partners',
    },
    founded: {
      disclaimer: 'Disclaimer',
      description: 'The aim of the project was to develop a common European Convergence Code of Good Governance for national sports federations.',
    },
  },
  cookies: {
    content: 'This site uses cookies to provide services at the highest level. By continuing to use the site, you agree to their use.',
    agreeButton: 'Agree',
    privacyPolicyButton: 'Privacy policy',
  },
  globalLabels: {
    showMore: 'Show more',
    showLess: 'Show less',
    expand: 'Expand for details',
    collapse: 'Show less details',
  },
};
