import {
  DownloadResources,
  Founded,
  Hero,
  Partners,
} from 'components/_domains/ActionPage';
import Action from "components/_domains/ActionPage/Action/Action";

function ActionPage() {
  return (
    <>
      <Hero />
      <DownloadResources />
      <Partners />
      <Action />
      <Founded />
    </>
  );
}

export default ActionPage;
