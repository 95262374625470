import { NavLink } from 'react-router-dom';
import {useContentContext} from "context/ContentContext";
import mainLogo from "assets/images/main-logo.svg";

function Logo() {
  const {data} = useContentContext();

  return (
    <NavLink to="/">
      <img src={data?.action_plus__header_logo.url ?? mainLogo} alt={data?.action_plus__header_logo.alt ?? "main logo"}/>
    </NavLink>
  );
}

export default Logo;
