import {createContext, useContext} from "react";
import {useQuery} from "@tanstack/react-query";

const getContent = async () => {
    const response = await fetch(`${process.env.REACT_APP_CONTENT_API_URL}/wp-json/action-plus/content`);
    return response.json();
}

const ContentContext = createContext(undefined);

export const ContentProvider = ({children}) => {
    const {data, isLoading, isError} = useQuery({ queryKey: ['content'], queryFn: getContent })

    return (
        <ContentContext.Provider
            value={{
                data,
                isLoading,
                isError
            }}
        >
            {children}
        </ContentContext.Provider>
    );
}

export function useContentContext() {
    const context = useContext(ContentContext);

    if (typeof context === "undefined") {
        throw new Error(
            "useContentContext should be used within the ContentContext provider!"
        );
    }

    return context;
}
