import { downloadResources } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ReactComponent as ArrowUp } from 'assets/images/arrow-up-white.svg';
import { ReactComponent as ArrowDown } from 'assets/images/arrow-down-white.svg';
import Container from "components/Grid/Container";
import HeadingWithDecor from "components/HeadingWithDecor/HeadingWithDecor";
import {useContentContext} from "context/ContentContext";

function DownloadResources() {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const sizes = {
    1: 'lg:grid-cols-1',
    2: 'lg:grid-cols-2',
    3: 'lg:grid-cols-3',
    4: 'lg:grid-cols-4',
    5: 'lg:grid-cols-5',
  };

  const gridSize = downloadResources.length < 6 ? sizes[downloadResources.length] : 'lg:grid-cols-6';

  const {data} = useContentContext();

  return (
      <section id="action-page-download-resources" className="py-10 relative">
        <div className="w-2/3 h-[60px] bg-white absolute left-0 bottom-full">
          <div className="triangle-shape-bottom w-[35px] h-full absolute top-0 left-full bg-white"/>
        </div>
        <div className="w-2/3 h-[60px] bg-white absolute right-0 top-full">
          <div className="triangle-shape w-[35px] h-full absolute top-0 right-full bg-white"/>
        </div>
        <Container>
          <div className="flex justify-center mb-14">
            <div className="flex items-center flex-col gap-5 w-[505px] max-w-full">
              <HeadingWithDecor title={data?.action_plus__resources_title ?? t('actionPage.downloadResources.title')}/>
              <p className="text-center">
                  {data?.action_plus__resources_description ?? ""}
              </p>
            </div>
          </div>
          <div className={`grid grid-cols-1 md:grid-cols-2 ${gridSize} gap-5`}>
            {data?.action_plus__resources?.map((item, index) => index < (isOpen ? data?.action_plus__resources?.length : 12) && (
                <a href={item.file} rel="noreferrer" target="_blank" key={item.label}
                   className="flex gap-2.5 justify-center items-center group hover:shadow-lg transition-all duration-200 p-2 cursor-pointer">
                  <svg width="34" height="34" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg"
                       className="min-w-[34px]">
                    <path
                        d="M45.5 87.5H74C71.05 94.85 63.9 100 55.5 100H20.5C9.45 100 0.5 91.05 0.5 80V40C0.5 29.8 8.15 21.4 18 20.15V60C18 75.15 30.35 87.5 45.5 87.5ZM88 21.25H98.6C98.3 20.8 97.95 20.4 97.55 20L80.5 2.95C80.1 2.55 79.7 2.2 79.25 1.95V12.5C79.25 17.3 83.2 21.25 88 21.25ZM88 28.75C79.05 28.75 71.75 21.45 71.75 12.5V0H45.5C34.45 0 25.5 8.95 25.5 20V60C25.5 71.05 34.45 80 45.5 80H80.5C91.55 80 100.5 71.05 100.5 60V28.75H88Z"
                        className="fill-action-red group-hover:fill-action-yellow"
                    />
                    <g filter="url(#filter0_b_66_99)">
                      <circle cx="50.5" cy="50" r="24" fill="white" fillOpacity="0.5"/>
                      <circle cx="50.5" cy="50" r="23.75" stroke="white" strokeWidth="0.5"/>
                    </g>

                    <circle cx="50.5" cy="50" r="12" fill="white"/>
                    <path
                        d="M53 55L48 55C46.6212 55 45.5 53.8787 45.5 52.5L45.5 50.5C45.5 50.2237 45.7237 50 46 50C46.2762 50 46.5 50.2237 46.5 50.5L46.5 52.5C46.5 53.3275 47.1725 54 48 54L53 54C53.8275 54 54.5 53.3275 54.5 52.5L54.5 50.5C54.5 50.2238 54.7237 50 55 50C55.2762 50 55.5 50.2238 55.5 50.5L55.5 52.5C55.5 53.8787 54.3787 55 53 55ZM50.8537 51.8538L52.8537 49.8537C53.0487 49.6587 53.0487 49.3425 52.8537 49.1462C52.6587 48.95 52.3425 48.9512 52.1462 49.1462L51 50.2925L51 46.5C51 46.2237 50.7762 46 50.5 46C50.2238 46 50 46.2237 50 46.5L50 50.2925L48.8538 49.1462C48.6587 48.9512 48.3425 48.9512 48.1462 49.1462C48.0487 49.2437 48 49.3712 48 49.5C48 49.6287 48.0487 49.7562 48.1462 49.8537L50.1462 51.8537C50.3412 52.0488 50.6575 52.0488 50.8537 51.8538Z"
                        fill="#898989"
                    />
                    <defs>
                      <filter
                          id="filter0_b_66_99"
                          x="6.5"
                          y="6"
                          width="88"
                          height="88"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="10"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_66_99"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_66_99" result="shape"/>
                      </filter>
                    </defs>
                  </svg>

                  <p className="text-left text-action-onyx w-full text-[13px]">
                    {item.label ?? ""}
                  </p>
                </a>
            ))}
          </div>
          {data?.action_plus__resources?.length > 12 && (
              <div className="w-full flex flex-wrap justify-center items-center mt-14">
                <button
                    type="button"
                    id="ohCQpJsH8dA3B4L6"
                    onClick={() => setOpen((prevState) => !prevState)}
                    className="flex gap-2 items-center group py-4 px-8 leading-none transition-colors border box-border relative bg-transparent hover:bg-action-pink text-action-pink hover:text-white border-action-pink"
                >
                  {isOpen ? t('globalLabels.showLess') : t('globalLabels.showMore')}
                  {isOpen ? (
                      <ArrowUp
                          className="[&_path]:transition [&_path]:fill-action-pink group-hover:[&_path]:fill-white"/>
                  ) : (
                      <ArrowDown
                          className="[&_path]:transition [&_path]:fill-action-pink group-hover:[&_path]:fill-white"/>
                  )}
                </button>
              </div>
          )}
        </Container>
      </section>
  );
}

export default DownloadResources;
