import PropTypes from 'prop-types';
import {twMerge} from "tailwind-merge";

function TooltipNonAbsolute({ children, className }) {
  return (
    <div className={twMerge("relative bg-action-green bg-opacity-[.08] p-3 border border-solid border-action-green", className)}>
      <div className="text-action-green font-semibold">
          {children}
      </div>
      <div style={{
          bottom: "calc(100% + 1px)",
      }} className="h-0 w-0 border-x-8 border-x-transparent border-b-[10px] border-b-action-green absolute left-1/2 transform -translate-x-1/2" />
      <div className="h-0 w-0 border-x-8 border-x-transparent border-b-[10px] border-b-[#ecf3ed] absolute bottom-full left-1/2 transform -translate-x-1/2" />
    </div>
  );
}

export default TooltipNonAbsolute;

TooltipNonAbsolute.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};
