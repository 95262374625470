import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import ActionPage from 'pages/action.page';
import Header from 'components/Header/Header';
import 'utils/i18n-instance';
import Footer from 'components/Footer/Footer';
import {useContentContext} from "context/ContentContext";

function App() {
  const {isLoading} = useContentContext();

  return isLoading ? <div></div> : (
    <>
      <Header />
      <Routes>
        <Route
          path="/"
          element={<ActionPage />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
