import NavigationItem from 'components/Navigation/NavigationItem';
import { useState } from 'react';
import {useContentContext} from "context/ContentContext";

function Navigation() {
  const [isOpen, setOpen] = useState(false);
  const {data} = useContentContext();

  return (
    <nav className="flex flex-wrap items-center">
      <ul className={`flex flex-wrap gap-6 absolute md:relative top-full md:top-auto inset-x-0 md:inset-x-auto bg-white p-3.5 md:p-0 ${isOpen ? '' : 'hidden md:flex'}`}>
        {data?.action_plus__main_menu?.map((navItem) => (
          <NavigationItem key={navItem.link} id={navItem.link} label={navItem.text} path={navItem.link} items={[]} />
        ))}
      </ul>
      <button
        type="button"
        className="w-6 h-6 bg-white flex flex-wrap flex-row justify-between items-center block md:hidden"
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <span className="h-0.5 rounded w-full bg-action-onyx block" />
        <span className="h-0.5 rounded w-full bg-action-onyx block" />
        <span className="h-0.5 rounded w-full bg-action-onyx block" />
      </button>
    </nav>
  );
}

export default Navigation;
