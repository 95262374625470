import React from 'react';
import { ReactComponent as CheckmarkIcon } from 'assets/images/check-icon.svg';
import {twMerge} from "tailwind-merge";

function CheckmarkListElem(props) {
    return (
        <li className={twMerge("relative pl-6", props.className)}>
            <CheckmarkIcon className="absolute top-1/2 left-0 translate-y-[-50%]" />
            {props.children}
        </li>
    );
}

export default CheckmarkListElem;
